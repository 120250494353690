<template lang="pug">
.reward-subscription-referral

  b(v-if="exclamation") Indique a Box Magenta e ganhe pontos!
  b(v-else) Indique a Box Magenta e ganhe pontos:

  p
    | Este é o seu cupom de desconto! Use ele para indicar a Box Magenta a outras pessoas que amam beleza!

  p
    | A cada vez que o seu cupom é utilizado em uma nova assinatura,&#32;
    b você ganha 5.000 pontos&#32;
    | que pode acumular e trocar por prêmios que são perfeitos pra você!

  .coupon
    Button.copy-coupon(
      :label="mgmCouponId",
      @click.prevent="copyCouponToClipboard",
      href="",
      size="large"
      rounded
    )

</template>
<script setup lang="ts">
import CustomerService from '~/services/customer/customerService'

const notifications = useNotifications()

const props = defineProps({
  exclamation: {
    type: Boolean,
    default: false
  },
  mgmCouponId: {
    type: String,
    default: null
  }
})

const copyCouponToClipboard = () => {
  copyToClipboard(props.mgmCouponId)

  notifications.success('Cupom de desconto copiado!')
}
</script>
<style lang="sass" scoped>
@import '~/assets/styles/variables'

.reward-subscription-referral

  p
    b
      color: $magenta

  .coupon
    margin: 32px 0
    text-align: center

    .copy-coupon
      font-size: 22px
      padding: 8px 32px
      height: 48px
      box-shadow: 0 4px 24px 0 rgba(238, 61, 138, 0.3)
</style>
