export enum RewardType {
  DISCOUNT = "DISCOUNT",
  PRODUCT_CREDIT = "PRODUCT_CREDIT",
  PRODUCT = "PRODUCT"
}

export enum RewardAvailability {
  UNAVAILABLE = "UNAVAILABLE",
  LIMITED = "LIMITED",
  AVAILABLE = "AVAILABLE"
}

export enum RedeemRewardResult {
  SUCCESS = "SUCCESS",
  OUT_OF_STOCK = "OUT_OF_STOCK",
  INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
  INELEGIBLE = "INELEGIBLE"
}

export interface Reward {
  tenant: string,
  id: number,
  type: RewardType,
  title: string,
  description: string,
  imageUrl: string,
  price: number,
  comparedPrice: number | null,
  availability: RewardAvailability,
  alreadyRedeemed: boolean,
  createdAt: string | undefined
}

export interface RedeemRewardRequest {
  rewardId: number
}

export interface RedeemRewardResponse {
  result: RedeemRewardResult
}

export interface GetRewardBalanceResponse {
  balance: number
}

export interface ListRewardsResponse {
  rewards: Reward[]
}

export default {
  async getSelfBalance(): Promise<GetRewardBalanceResponse> {
    return await apiGet(`customer/self/rewards/balance`)
  },
  async listSelfRewards(): Promise<ListRewardsResponse> {
    return await apiGet(`customer/self/rewards`)
  },
  async selfRedeemReward(request: RedeemRewardRequest): Promise<RedeemRewardResponse> {
    return await apiPost(`customer/self/rewards/redeem`, { body: request })
  },
};
