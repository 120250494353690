<template lang="pug">
.reward-container(:class="{disabled}")
  .reward(:onClick="open")
    .reward-image
      img(:src="reward.imageUrl")

    .compared-reward-price(v-if="formattedComparedPrice != null") {{ formattedComparedPrice }} pontos

    .reward-price {{ formattedPrice }} pontos

    h3 {{ reward.title }}

    .progress(v-if="!disabled")
      .progress-bar(:style="`width: ${progress}%`")

  .notices
    .notice(v-if="isNew") Novo
    .notice(v-if="reward.alreadyRedeemed") Resgatado
    .notice(v-else-if="reward.availability === 'UNAVAILABLE'") Esgotado
    .notice(v-else-if="reward.availability === 'LIMITED'") Últimas unidades

  teleport(to="#modals")
    .reward-popup(v-if="opened")
      .reward-popup-dismiss(:onClick="toggleOpened")

      .reward-popup-content
        .reward-image
          img(:src="reward.imageUrl")

        .compared-reward-price(v-if="formattedComparedPrice != null") {{ formattedComparedPrice }} pontos

        .reward-price {{ formattedPrice }} pontos

        h3 {{ reward.title }}

        .reward-description(v-if="reward.description", v-html="reward.description")

        Button.action(
          :disabled="buttonDisabled",
          :loading="redeeming",
          :label="buttonLabel",
          @click="redeem",
          rounded
        )

        .reward-popup-dismiss-button(:onClick="toggleOpened") X
</template>
<script setup lang="ts">
import type { PropType, WatchStopHandle } from 'vue'
import type { Reward } from '~/services/reward/rewardService'

import { default as RewardService, RewardAvailability, RedeemRewardResult, RewardType } from '~/services/reward/rewardService'
import { useAppScroll } from '~/store/appScroll'
import { useGtm } from '@gtm-support/vue-gtm'

const props = defineProps({
  reward: {
    type: Object as PropType<Reward>,
    required: true
  },
  balance: {
    type: Number,
    required: true,
  }
})

const emit = defineEmits(['redeem'])

const appScroll = useAppScroll()
const notifications = useNotifications()
const router = useRouter()
const route = useRoute()

const numberFormater = new Intl.NumberFormat('pt-BR')

const gtm = useGtm()
const opened = ref(false)
const redeeming = ref(false)
const redeemed = ref(false)

const watchRouteStopHandle = ref<WatchStopHandle>()

const rewardHash = computed(() => `#presente-${props.reward.id}`)

const disabled = computed(() =>
  props.reward.availability == RewardAvailability.UNAVAILABLE || props.reward.alreadyRedeemed
)

const isNew = computed(() => {
  if (!props.reward.createdAt) {
    return false
  }

  const createdAt = new Date(props.reward.createdAt)
  const now = new Date()

  // Created in the last 60 days
  return createdAt.getTime() > now.getTime() - (1000 * 60 * 60 * 24 * 60)
})

const toggleOpened = () => {
  if (!opened.value) {
    open()
  } else {
    close()
  }
}

const open = () => {
  opened.value = true
  appScroll.disableScroll()

  router.push({
    hash: rewardHash.value
  })

  watchRouteStopHandle.value = watch(() => route.hash, () => {
    if (opened.value && route.hash != rewardHash.value) {
      close()
    }
  })

  const dataLayer = gtm?.dataLayer()

  if (dataLayer) {
    dataLayer.push({
      event: "view-reward",
      "rewardId": props.reward.id
    })
  }

  redeemed.value = false
}

const close = () => {
  opened.value = false
  appScroll.enableScroll()

  watchRouteStopHandle.value?.()

  if (route.hash == rewardHash.value) {
    router.replace({
      hash: ''
    })
  }
}

const buttonLabel = computed(() => {
  if (redeemed.value) {
    return 'Resgatado!'
  } if (props.balance < props.reward.price) {
    return 'Saldo Insuficiente'
  } else {
    return 'Resgatar'
  }
})

const buttonDisabled = computed(() => props.balance < props.reward.price)

const progress = computed(() => {
  if (props.reward.price == 0) {
    return 100
  } else {
    return Math.min(1, props.balance / props.reward.price) * 100.0
  }
})

const redeem = async () => {
  if (redeemed.value) return

  redeeming.value = true

  try {
    const response = await RewardService.selfRedeemReward({ rewardId: props.reward.id })

    switch (response.result) {
      case RedeemRewardResult.SUCCESS:
        if (props.reward.type == RewardType.PRODUCT) {
          notifications.success("Prêmio resgatado com sucesso! Você irá recebê-lo em sua próxima Box Magenta")
        } else {
          notifications.success("Prêmio resgatado com sucesso!")
        }

        redeemed.value = true

        emit("redeem", response)
        break
      case RedeemRewardResult.OUT_OF_STOCK:
        notifications.warning("Poxa! Este prêmio esgotou e não foi possível trocar os seus pontos")
        break
      case RedeemRewardResult.INSUFFICIENT_FUNDS:
        notifications.warning("Poxa! Você ainda não possui pontos suficientes para resgatar este prêmio")
        break
      case RedeemRewardResult.INELEGIBLE:
        notifications.warning("Para resgatar este prêmio, ative a sua assinatura Box Magenta")
        break
    }
  } catch(e) {
    captureException(new Error('Failed to redeem reward', { cause: e}))

    notifications.error("Falha ao resgatar prêmio. Por favor, tente novamente mais tarde")
  } finally {
    redeeming.value = false
  }
}

const formattedPrice = computed(() => numberFormater.format(props.reward.price))

const formattedComparedPrice = computed(() => {
  if (props.reward.comparedPrice != null && props.reward.comparedPrice > props.reward.price) {
    return numberFormater.format(props.reward.comparedPrice)
  } else {
    return null
  }
})

onUnmounted(() => {
  if (opened.value) {
    appScroll.enableScroll()
  }

  watchRouteStopHandle.value?.()
})
</script>
<style lang="sass" scoped>
@import '~/assets/styles/mixins'
@import '~/assets/styles/variables'

.reward-container
  position: relative
  min-width: 156px

  &.disabled
    pointer-events: none

    .reward
      filter: grayscale(80%)

      // We need to separate each reward to a new layer
      // because filter: grayscale is really slow on WebKit
      // and this enables it each layer to be computed
      // separately, which is much faster for some reason
      transform: translateZ(0)

  .reward
    display: flex
    flex-direction: column
    justify-content: flex-start
    width: 100%
    height: 278px
    padding: 16px
    background-color: $beige
    color: #000
    border-radius: 8px
    text-align: left
    cursor: pointer

    @include phablet
      padding: 8px

    h3
      margin: 0 0
      color: #000
      font-size: 14px

    .reward-image
      background-color: #FFF
      border-radius: 8px
      margin-bottom: 16px
      padding: 4px
      text-align: center

      img
        width: 120px
        height: 120px
        object-fit: contain

    .reward-price
      color: $magenta
      font-size: 18px
      font-weight: bold
      margin-bottom: 8px

    .compared-reward-price
      font-size: 12px
      font-weight: bold
      text-decoration: line-through
      margin-bottom: 0

  .notices
    position: absolute
    display: flex
    flex-direction: column
    justify-content: right
    top: 4px
    right: -12px
    width: 66px

    .notice
      display: flex
      justify-content: center
      align-items: center
      font-size: 12px
      font-weight: bold
      color: white
      background: $magenta
      min-height: 24px
      border-radius: 12px
      text-align: center
      line-height: 13px
      margin: 6px 0 0
      padding: 4px

  .progress
    position: absolute
    height: 8px
    width: 100%
    bottom: 0
    left: 0
    border-radius: 0 0 8px 8px
    overflow: hidden

    .progress-bar
      position: absolute
      height: 8px
      bottom: 0
      left: 0
      background: $magenta

</style>
<style lang="sass">
@import '~/assets/styles/mixins'
@import '~/assets/styles/variables'

#modals
  .reward-popup
    position: fixed
    display: flex
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    z-index: 9998
    align-items: center
    justify-content: center
    padding: 16px

    .reward-popup-dismiss
      position: absolute
      top: -50vh
      left: -50vw
      width: 200vw
      height: 200vh
      background-color: rgba(0, 0, 0, 0.25)
      animation: fade-in ease-out .16s forwards

    .reward-popup-content
      position: relative
      display: flex
      flex-direction: column
      justify-content: flex-start
      align-items: left
      padding: 16px
      padding-bottom: 16px
      max-width: 480px
      min-height: 320px
      max-height: 720px
      background-color: $beige
      color: #000
      border-radius: 8px
      z-index: 9999
      box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.2)
      opacity: 0
      transform: translateY(32px)
      animation: fade-in ease-out .16s .16s forwards, slide-up ease-out .16s .16s forwards

      @include extrasmall
        max-height: 640px

      @keyframes slide-up
        from
          transform: translateY(32px)

        to
          transform: translateY(0)

      h3
        margin: 0
        margin-bottom: 16px
        font-size: 18px
        text-align: left

      .reward-image
        background-color: #FFF
        border-radius: 8px
        margin-bottom: 16px
        padding: 16px
        text-align: center

        img
          min-width: 120px
          max-width: 180px
          min-height: 120px
          max-height: 180px
          object-fit: contain

      .reward-price
        color: $magenta
        font-size: 22px
        font-weight: bold
        text-align: left
        margin-bottom: 16px

      .compared-reward-price
        font-size: 14px
        font-weight: bold
        text-align: left
        text-decoration: line-through
        margin-bottom: 0

      .reward-description
        margin-bottom: 16px
        text-align: justify
        max-height: 236px
        overflow-y: auto

        p
          margin: 0

      .reward-expiration
        font-size: 12px

      a.action
        display: block
        margin: 0 auto
        width: 240px
        margin-bottom: 16px
        box-shadow: 0 4px 16px 0 rgba(238, 61, 138, 0.4)

      .reward-popup-dismiss-button
        position: absolute
        top: -16px
        right: -16px
        width: 32px
        height: 32px
        border-radius: 16px
        background-color: $magenta
        box-shadow: 0 4px 16px 0 rgba(238, 61, 138, 0.4)
        color: #FFF
        line-height: 32px
        font-weight: bold
        text-align: center
        cursor: pointer

        &:hover
          background-color: scale-color($magenta, $lightness: +15%)
</style>
