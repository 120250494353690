<template lang="pug">
.rewards(v-if="!loading")
  h1 Resgatar prêmios
  .subscript
    | Acumule e troque pontos Magenta por prêmios que são perfeitos pra você!
    br
    b Os prêmios que resgatar serão enviados na sua próxima box.

  RewardSubscriptionReferral(
    :mgmCouponId="mgmCouponId",
    exclamation
  )

  .balance
    picture
      source(
        srcset="~/assets/icons/magenta-coin-2x.png 2x, ~/assets/icons/magenta-coin-3x.png 3x, ~/assets/icons/magenta-coin-4x.png 4x"
      )
      img(
        src="~/assets/icons/magenta-coin.png",
        width="24",
        height="24"
      )
    | Seu saldo: {{ formattedBalance }} pontos

  br

  Button(
    label="Como ganhar pontos?",
    :icon="['fas', 'circle-question']",
    @click="toggleHowToOpen",
    size="small",
    rounded
  )

  PaginatedList(
    name="rewards",
    :items="rewards",
    :itemsPerPage="12",
    :breakpoints="{ 684: { itemsPerPage: 18 }, 908: { itemsPerPage: 24 } }",
    v-slot="{ items }"
  )
    .reward-list
      RewardItem(
        v-for="reward in items",
        :key="reward.id",
        :reward="reward",
        :balance="balance",
        @redeem="reload")

  teleport(to="#modals")
    Dialog(
      title="Como ganhar pontos?",
      v-model:open="howToOpen",
    )
      .reward-how-to-popup

        p Para acumular pontos que você pode trocar por prêmios, existem algumas coisas que você pode fazer:

        p
          b Receba uma Box Magenta:
          br
          | A cada Box Magenta recebida,&#32;
          b.magenta você ganha 1.000 pontos

        RewardSubscriptionReferral(
          :mgmCouponId="mgmCouponId"
        )

.rewards(v-else)
  .rewards-loading
    Loading

</template>
<script setup lang="ts">
import type { Reward } from '~/services/reward/rewardService'

import { default as RewardService, RewardAvailability, RewardType } from '~/services/reward/rewardService'
import RewardItem from './Reward.vue'
import RewardSubscriptionReferral from './RewardSubscriptionReferral.vue'
import CustomerService from '~/services/customer/customerService'
import { type MemberGetMemberCoupon } from '~/services/subscription/memberGetMember/memberGetMember'

useHead({
  title: 'Resgatar prêmios'
})

definePageMeta({
  requiresAuth: true,
  breadcrumb: [
    {
      title: 'Minha conta',
      link: { name: 'dashboard' },
    },
    {
      title: 'Resgatar prêmios',
      link: { name: 'reward-list' },
    },
  ],
})

const notifications = useNotifications()

const numberFormater = new Intl.NumberFormat('pt-BR')

const loading = ref(true)
const rewards = ref<Reward[]>([])
const balance = ref<number>(0)
const mgmCoupon = ref<MemberGetMemberCoupon | null>(null)
const howToOpen = ref(false)

const formattedBalance = computed(() => {
  if (balance.value!!) {
    return numberFormater.format(balance.value)
  } else {
    return 0
  }
})

const toggleHowToOpen = () => {
  howToOpen.value = !howToOpen.value
}

const mgmCouponId = computed(() => mgmCoupon.value?.couponId.toLocaleUpperCase() ?? '')

const reload = () =>
  Promise.all([
    RewardService.getSelfBalance(),
    RewardService.listSelfRewards(),
    CustomerService.findSelfMemberGetMemberCoupon()
  ])
  .then(([balanceResponse, rewardsResponse, mgmCouponResponse]) => {
    mgmCoupon.value = mgmCouponResponse
    balance.value = balanceResponse.balance
    rewards.value =
      rewardsResponse.rewards
        .sort((a, b) => {
          if (a.price < b.price) {
            return -1
          } else if (b.price < a.price) {
            return 1
          } else {
            return 0
          }
        })
        .sort((a, b) => {
          if (a.availability == RewardAvailability.UNAVAILABLE && b.availability != RewardAvailability.UNAVAILABLE) {
            return 1
          } else if (b.availability == RewardAvailability.UNAVAILABLE && a.availability != RewardAvailability.UNAVAILABLE) {
            return -1
          } else {
            return 0
          }
        })

    loading.value = false
  })
  .catch((error) => {
    notifications.error("Falha ao carregar prêmios. Por favor, tente novamente mais tarde")

    captureException(new Error('Failed to load reward list', { cause: error }))
  })

onMounted(() => {
  reload()
})
</script>
<style lang="sass" scoped>
@import '~/assets/styles/mixins'
@import '~/assets/styles/variables'

.rewards
  position: relative
  max-width: 988px
  margin: 0 auto
  padding: 0 14px
  text-align: left
  animation: fade-in .2s forwards

  h1
    margin-bottom: 8px

  .subscript
    margin-bottom: 24px

  .balance
    display: inline-block
    background-color: $acquaBlue
    border-radius: 24px
    padding: 8px 16px 8px 8px
    font-weight: bold
    margin-bottom: 16px

    img
      height: 24px
      width: 24px
      margin-right: 8px
      vertical-align: middle

  .rewards-loading
    display: flex
    height: 320px
    justify-content: center
    align-items: center

  .reward-list
    display: grid
    margin: 16px 0 0
    grid-gap: 16px
    grid-template-columns: repeat(auto-fill, minmax(156px, 1fr))

  @include breakpoint(phablet)
    .reward-list
      grid-template-columns: repeat(auto-fill, minmax(208px, 1fr))


</style>
<style lang="sass">
@import '~/assets/styles/variables'

.reward-how-to-popup

  .magenta
    color: $magenta
</style>
